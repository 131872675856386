export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTopLine: true,
    lightTextDesc: true,
    headline: 'Soy Silvia',
    description:
      'Desde muy pequeña todo lo relacionado con el mundo alternativo y el Tattoo me ha llamado la atención, tras terminar mis estudios artísticos decidí meterme de lleno en este mundo.',
    buttonLabel: 'RESERVA YA',
    imgStart: '',
    img: require('../../images/foto.png'),
    alt: 'Credit Card'
  };
  

import React, { useState, useEffect } from 'react';
import { Nav, NavbarContainer, NavLogo, NavIcon, HamburgerIcon, NavMenu, NavItem, NavLinks } from './Navbar.elements';
import { FaTimes, FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import logo from './logo.png'; // Asegúrate de que la ruta sea correcta

function Navbar() {
    const [click, setClick] = useState(false);
    // const [button, setButton] = useState(true); // Comentado porque no se está usando

    const [homeClick, setHomeClick] = useState(false);
    const [servicesClick, setServicesClick] = useState(false);
    const [productsClick, setProductsClick] = useState(false);

    const handleHomeClick = () => {
        setHomeClick(true);
        setProductsClick(false);
        setServicesClick(false);
    };

    const handleServicesClick = () => {
        setHomeClick(false);
        setProductsClick(false);
        setServicesClick(true);
    };

    const handleProductsClick = () => {
        setHomeClick(false);
        setProductsClick(true);
        setServicesClick(false);
    };

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            // setButton(false); // Comentado porque no se está usando
        } else {
            // setButton(true); // Comentado porque no se está usando
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', showButton);
        return () => {
            window.removeEventListener('resize', showButton);
        };
    }, []);

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to="/">
                            <NavIcon src={logo} alt="Logo" />
                        </NavLogo>
                        <HamburgerIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </HamburgerIcon>
                        <NavMenu onClick={handleClick} click={click}>
                            <NavItem onClick={handleHomeClick} homeClick={homeClick}>
                                <NavLinks to="/" onClick={closeMobileMenu}>
                                    INICIO
                                </NavLinks>
                            </NavItem>
                            <NavItem onClick={handleServicesClick} servicesClick={servicesClick}>
                                <NavLinks to="/services" onClick={closeMobileMenu}>
                                    DISEÑOS
                                </NavLinks>
                            </NavItem>
                            <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                                <NavLinks to="/Products" onClick={closeMobileMenu}>
                                    SOBRE MI
                                </NavLinks>
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;

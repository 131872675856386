import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterLinksContainer,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';

function Footer() {

  const date = new Date();

  return (
    <FooterContainer>
      <FooterLinksContainer>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
          <a href='https://codigocentro.com' target='_blank' rel='noopener noreferrer'>
              <img src='/images/white.png' alt='Logo' style={{ width: '202px', height: '56px' }} />
            </a>
          </SocialLogo>
          <WebsiteRights>
            <a href='https://codigocentro.com' target='_blank' rel='noopener noreferrer' style={{ color: 'inherit', textDecoration: 'none' }}>
              CÓDIGOCentro© {date.getFullYear()}
            </a>
          </WebsiteRights>
          <SocialIcons>
            <SocialIconLink href='https://www.facebook.com/people/Kifftattoo/100069569453027/?mibextid=LQQJ4d&rdid=mg4JcoXBz2pgJFxF&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FcYDgEPwSpjogrd99%2F%3Fmibextid%3DLQQJ4d' target='_blank' aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href='https://www.instagram.com/kiff.tattoo/' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href='https://www.tiktok.com/@kiff.tattoo' target='_blank' aria-label='YouTube'>
              <FaYoutube />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;

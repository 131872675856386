import React, { useEffect, useState } from "react";
import Swipe from "react-easy-swipe";
import "./styles/index.css";

function Carousel({
  data,
  time,
  width,
  height,
  captionStyle,
  slideNumberStyle,
  radius,
  slideNumber,
  style,
  captionPosition,
  dots,
  automatic,
  pauseIconColor,
  pauseIconSize,
  slideBackgroundColor,
  slideImageFit,
  showNavBtn = true,
}) {
  const [slide, setSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [change, setChange] = useState(0);

  const addSlide = (n) => {
    if (slide + n >= data.length) setSlide(0);
    else if (slide + n < 0) setSlide(data.length - 1);
    else setSlide(slide + n);
  };

  useEffect(() => {
    if (automatic) {
      let index = slide;
      const interval = setInterval(() => {
        if (!isPaused) {
          setSlide(index);
          index++;
          if (index >= data.length) index = 0;
          if (index < 0) index = data.length - 1;
        }
      }, time ? time : 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isPaused, change, automatic, slide, time, data.length]);

  useEffect(() => {
    const slides = document.getElementsByClassName("carousel-item");
    const dots = document.getElementsByClassName("dot");

    let slideIndex = slide;
    let i;
    for (i = 0; i < data.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }

    if (slides[slideIndex] !== undefined)
      slides[slideIndex].style.display = "block";
    if (dots[slideIndex] !== undefined) dots[slideIndex].className += " active";
  }, [slide, isPaused, data.length]);

  return (
    <div style={style} className="box">
      <div
        style={{
          maxWidth: width ? width : "600px",
          height: height ? height : "400px",
        }}
      >
        <Swipe
          onSwipeRight={() => {
            addSlide(-1);
            setChange(!change);
          }}
          onSwipeLeft={() => {
            addSlide(1);
            setChange(!change);
          }}
        >
          <div
            className="carousel-container"
            style={{
              maxWidth: width ? width : "600px",
              height: height ? height : "400px",
              backgroundColor: slideBackgroundColor
                ? slideBackgroundColor
                : "darkgrey",
              borderRadius: radius,
            }}
          >
            {data.map((item, index) => {
              return (
                <div
                  className="carousel-item fade"
                  style={{
                    maxWidth: width ? width : "600px",
                    height: height ? height : "400px",
                  }}
                  onMouseDown={() => {
                    automatic && setIsPaused(true);
                  }}
                  onMouseUp={() => {
                    automatic && setIsPaused(false);
                  }}
                  onMouseLeave={() => {
                    automatic && setIsPaused(false);
                  }}
                  onTouchStart={() => {
                    automatic && setIsPaused(true);
                  }}
                  onTouchEnd={() => {
                    automatic && setIsPaused(false);
                  }}
                  key={index}
                >
                  {slideNumber && (
                    <div className="slide-number" style={slideNumberStyle}>
                      {index + 1} / {data.length}
                    </div>
                  )}
                  <img
                    src={item.image}
                    alt={item.caption}
                    className="carousel-image"
                    style={{
                      borderRadius: radius,
                      objectFit: slideImageFit ? slideImageFit : "cover",
                      height: height ? height : "100px",
                    }}
                  />
                  {isPaused && (
                    <div
                      className="pause-icon pause"
                      style={{
                        color: pauseIconColor ? pauseIconColor : "white",
                        fontSize: pauseIconSize ? pauseIconSize : "40px",
                      }}
                    >
                      II
                    </div>
                  )}
                  <div
                    className={`carousel-caption-${
                      captionPosition ? captionPosition : "bottom"
                    }`}
                    style={captionStyle}
                    dangerouslySetInnerHTML={{ __html: item.caption }}
                  ></div>
                </div>
              );
            })}

            {showNavBtn && (
              <a
                className="prev"
                onClick={() => {
                  addSlide(-1);
                  setChange(!change);
                }}
              >
                &#10094;
              </a>
            )}
            {showNavBtn && (
              <a
                className="next"
                onClick={() => {
                  addSlide(1);
                  setChange(!change);
                }}
              >
                &#10095;
              </a>
            )}
            {dots && (
              <div className="dots">
                {data.map((item, index) => {
                  return (
                    <span
                      className="dot"
                      key={index}
                      onClick={() => {
                        setSlide(index);
                        setChange(!change);
                      }}
                    ></span>
                  );
                })}
              </div>
            )}
          </div>
        </Swipe>
      </div>
    </div>
  );
}

export default Carousel;

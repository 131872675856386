import React from 'react';
import { Pricing } from '../../components';


function Services() {
  return (
    <>
      <Pricing />

    </>
  );
}

export default Services;
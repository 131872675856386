export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  headline: 'Tattoo Artist',
  description:
    'Bienvenido a mi mundo, donde cada tatuaje es una obra de arte personalizada y cada cliente es único. Transformo tus ideas en arte sobre tu piel.',
  buttonLabel: 'RESERVA YA',
  imgStart: '',
  img: require('../../images/imagen1.png'),
  alt: 'logo kifftattoo',
  start: ''
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  headline: 'Tu piel, tu historia, mi arte',
  description:
    "Ya sea tu primer tatuaje o uno más para agregar a tu colección, estoy aquí para guiarte en cada paso del proceso, desde la conceptualización del diseño hasta el cuidado posterior. Ven y descubre cómo podemos juntos crear arte que llevarás contigo para siempre.",
  buttonLabel: 'RESERVA YA',
  imgStart: '',
  img: require('../../images/3.jpg'), // Cambia a la imagen que prefieras
  alt: 'Vault',
  start: ''
};


export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  headline: 'Mis Estilos',
  description:
    "Desde líneas delicadas hasta trazos audaces, ofrezco una variedad de estilos para que encuentres el diseño que mejor refleje tu personalidad.",
  buttonLabel: 'RESERVA YA',
  imgStart: 'start',
  img: require('../../images/img5.jpg'),
  alt: 'Vault',
  start: 'true'
};

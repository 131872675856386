import React from 'react';
import { IconContext } from 'react-icons/lib';
import { PricingSection, PricingWrapper } from './Pricing.elements';
import { Carousel } from '../../lib';

const Pricing = () => {
  const data = [
    {
      image: require("../../images/des/des1.jpeg"),
    },
    {
      image: require("../../images/des/des2.jpeg"),
    },
    {
      image: require("../../images/des/des3.jpeg"),
    },
    {
      image: require("../../images/des/des5.jpeg"),
    },
    {
      image: require("../../images/des/des6.jpeg"),
    },
    {
      image: require("../../images/des/des8.jpeg"),
    },
    {
      image: require("../../images/des/des9.jpeg"),
    },
    {
      image: require("../../images/pier/pier1.jpeg"),
    },
    {
      image: require("../../images/pier/pier2.jpeg"),
    },
    {
      image: require("../../images/pier/pier3.jpg"),
    },
    {
      image: require("../../images/pier/pier4.jpg"),
    },
    {
      image: require("../../images/pier/pier5.jpg"),
    },
    {
      image: require("../../images/pier/pier6.jpg"),
    },
    {
      image: require("../../images/gem/gem1.jpg"),
    },
    {
      image: require("../../images/gem/gem2.jpg"),
    },
    {
      image: require("../../images/gem/gem3.jpg"),
    },
    {
      image: require("../../images/gem/gem4.jpg"),
    },
  ];

  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <h2 style={{ textAlign: 'center',  fontSize: '50px' }}>Algunos de mis trabajos</h2>
          <div style={{ padding: "20px" }}>
            <Carousel
              data={data}
              time={2000}
              width="850px"
              height="500px"
              captionStyle={{ fontSize: '2em', fontWeight: 'bold' }}
              radius="10px"
              slideNumber={true}
              slideNumberStyle={{ fontSize: '20px', fontWeight: 'bold' }}
              captionPosition="bottom"
              automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="100px"
              showNavBtn={true}
              style={{ textAlign: "center", maxWidth: "850px", margin: "40px auto" }}
            />
          </div>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}

export default Pricing;
